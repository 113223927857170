import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { device } from 'components/device';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 2.625rem;

  p:nth-child(2) {
    margin-bottom: 2rem;
  }

  @media ${device.laptop} {
    flex-direction: row;
  }

  h1 {
    width: 100%;
    margin-bottom: 3.125rem;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.875rem;
  }

  p {
    color: #231f20;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.625rem;
    width: 47%;

    @media ${device.laptop} {
      width: 100%;
      font-size: 0.875rem;
      line-height: 1.375rem;
    }
  }
`;

const BoardsAndLadies = ({ markdown }) => {
  return (
    <Container>
      <ReactMarkdown children={markdown || '-'} />
    </Container>
  );
};

export default BoardsAndLadies;
