// Libs
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';
import GraphImg from 'graphcms-image';

// Components
import Layout from 'components/layout';
import SEO from 'components/seo';
import BoardsAndLadies from 'components/boardsAndLadies';

import { FeaturedMarketing } from 'components/CmsComponents/FeaturedMarketing';
import { HeaderContext } from 'context/header';

import Table from 'components/table';
import QualityCommittee from 'components/qualityCommittee';
import { device } from 'components/device';
import PromptServiceContent from 'components/promptService/promptServiceContent';
import { InformationCardDownload } from 'components/information-card/InformationCard';

// Images
import ArrowUp from 'images/icons/arrow-up.svg';
import ArrowDown from 'images/icons/arrow-down-lightblue.svg';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '20px'};

  @media ${device.laptop} {
    flex-direction: column;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 0;
  }
`;

const Part = styled.div`
  display: ${props => props.isDisplay};
  grid-column: ${props => props.gridColumn};
  border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  padding-top: ${props => props.paddingTop && '70px'};
  border-top: ${props => props.borderTop && '1px solid #C6C8CC'};

  section :first-child {
    padding: ${props =>
      props.notPadding
        ? '70px 0 0 !important'
        : props.notPaddingTop && '0 0 70px !important'};
    border-bottom: ${props =>
      (props.notPaddingTop || props.isShortBorder) && '1px solid #C6C8CC'};
    p {
      padding-left: ${props => props.notLeft && '0'};
    }

    @media ${device.laptop} {
      width: ${props => props.notPaddingTop && '100%'};
      padding: ${props =>
        props.notPadding
          ? '30px 0 0 !important'
          : props.notPaddingTop && '30px 0 !important'};
      margin: 0;
      border-bottom: ${props =>
        props.notPaddingTop || (props.isShortBorder && 'unset')};
      border-top: ${props => props.isBorderTop && '1px solid #C6C8CC'};
    }

    @media ${device.tablet} {
      padding: ${props => props.notPaddingTop && '0 0 30px !important'};
    }
    @media ${device.tablet} {
      padding: ${props => props.notPaddingTop && '0 0 30px !important'};
    }
  }

  section :last-child {
    padding: ${props => props.shortPadding && '20px 0 70px'};
    @media ${device.laptop} {
      padding: ${props => props.shortPadding && '0 0 30px'};
    }
  }

  section {
    div {
      div {
        h2 {
          width: ${props => props.largeTitle && '28rem'};

          @media ${device.laptop} {
            width: 100%;
          }
        }
      }
    }
  }

  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isCDIBelaVista ? '0' : '1.875rem')};
    }

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    padding-bottom: ${props => props.paddingPartIndex && '4.375rem'};
    flex-direction: column;
    margin-top: 0;
    padding-top: ${props => props.paddingTop && '30px'};
    padding-bottom: ${props => props.notPaddingTop && '30px'};
    border-bottom: ${props =>
      props.borderBottom || props.noBorderTopLap
        ? 'unset'
        : props.notPaddingTop && '1px solid #C6C8CC'};
    border-top: ${props => props.paddingTop && '1px solid #C6C8CC'};
    border-bottom: ${props => props.paddingPartIndex && '1px solid #C6C8CC'};
    section {
      div {
        display: ${props => props.paddingTop && 'flex'};
      }
    }
  }

  @media ${device.tablet} {
    padding-top: ${props => props.paddingTop && '0'};
    border-top: ${props => props.paddingTop && 'unset'};
  }

  @media ${device.mobile} {
    margin-bottom: ${props => props.paddingPartIndex && '0'};
    padding-top: ${props => props.paddingPartIndex && '0'};
    padding-bottom: ${props => props.paddingPartIndex && '0'};
  }
`;

const Title = styled.h2`
  margin-top: 2rem;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 1.5rem;

  @media ${device.laptop} {
    margin-top: 1.9375rem;
    margin-bottom: 1.125rem;
    font-size: 1.125rem;
  }
`;

const TitleSection = styled.h1`
  width: 100%;
  font-size: 35px;
  line-height: 3.75rem;
  font-weight: 700;
  color: rgb(35, 31, 32);
  margin-top: 2rem;
  margin-bottom: 2rem;
  white-space: pre-line;
`;

const GridMainServices = styled.div`
  margin: 0;
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;
  background: #f4f5f7;
  overflow-x: hidden;
  margin-bottom: 30px;

  @media ${device.laptop} {
    width: 100vw;
    margin: 0 -20px;
    display: flex;
    flex-direction: column-reverse;
    column-gap: 10px;
  }
`;

const DropdownContent = styled.div`
  padding: 4.375rem 0 4.375rem 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c6c8cc;

  @media ${device.laptop} {
    padding: 1.875rem 0;
  }
`;

const DropdownContainer = styled.div`
  padding: 1.1875rem 1.3125rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: #f4f5f7;
  cursor: pointer;
`;

const DropdownBoxTitle = styled.div`
  margin-bottom: ${props => props.isOpen && '2.375rem'};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: ${props => props.isOpen && 'center'};

  img {
    cursor: pointer;
  }
`;

const DropdownTitle = styled.h2`
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.625rem;
  color: #45a7df;
`;

const ReadyToAttend = ({ page, gestaoHsl }) => {
  const [isDesktop, setDesktop] = useState(true);
  const [collapseOpen, setCollapseOpen] = useState([0]);

  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('5.875rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  const getMarkdown = (
    page,
    fragmentId,
    isMarkdown = false,
    assets = false
  ) => {
    const { fragments } = page[0];
    const findFragment = fragments.find(item => item.id === fragmentId);

    if (isMarkdown) {
      const { markdown } = flatten(findFragment.localizations)[0];
      return markdown;
    } else if (assets) {
      return findFragment.assetpicker;
    } else {
      return flatten(findFragment.localizations);
    }
  };

  const renderDropdown = useCallback(
    (drop, indexList) => {
      let statusCollapse = collapseOpen.includes(indexList);

      return (
        <DropdownContainer>
          <DropdownBoxTitle isOpen={statusCollapse}>
            <DropdownTitle>{drop.name}</DropdownTitle>
            {statusCollapse ? (
              <img src={ArrowUp} alt={`Fechar ${drop.name}`} />
            ) : (
              <img src={ArrowDown} alt={`Visualizar ${drop.name}`} />
            )}
          </DropdownBoxTitle>
          {statusCollapse && (
            <Table
              data={drop.gestaoHsls}
              key={drop.gestaoHsls}
              selected={statusCollapse}
            />
          )}
        </DropdownContainer>
      );
    },
    [collapseOpen]
  );

  const getGraphCMSMultipleAssets = (page, fragmentId) => {
    const { fragments } = page[0];
    const findFragment = fragments.find(item => item.id === fragmentId);

    if (!findFragment) throw new Error(`Fragment '${fragmentId}' not found.`);
    if (!findFragment.assetpickerMultiple)
      throw new Error(`Fragment '${fragmentId}' doesn't have multiple assets.`);

    return findFragment.assetpickerMultiple;
  };

  const renderPage = (page, isDesktop, isMobile) => {
    const handleListInformationPeoples = idCollapse => {
      let auxCollapse = collapseOpen.slice();

      const item = auxCollapse.includes(idCollapse);

      if (!item) {
        auxCollapse.push(idCollapse);
      } else {
        const removeCollapse = auxCollapse.indexOf(idCollapse);
        auxCollapse.splice(removeCollapse, 1);
      }

      setCollapseOpen(auxCollapse);
    };

    const featuredMarketingContent = page[0]?.featuredMarketing;

    const images = getGraphCMSMultipleAssets(page, 'ckusmhy8g4ef20b75wg0ll2u7');

    return (
      <>
        {featuredMarketingContent && (
          <FeaturedMarketing content={featuredMarketingContent} />
        )}
        <GridContainer isDesktop={isDesktop}>
          <Part gridColumn={'2 / -2'} notPaddingTop noBorderTopLap />
          <Part gridColumn={'2 / -2'} largeTitle notLeft>
            <TitleSection>Conselhos, Diretoria e Comissões</TitleSection>
            <Title>Gestão 2024-2027</Title>
          </Part>
          <Part gridColumn={'2 / -2'} largeTitle notLeft>
            {gestaoHsl.map((drop, indexList) => (
              <>
                {indexList === 3 && (
                  <Part gridColumn={'2 / -2'} largeTitle notLeft>
                    <BoardsAndLadies
                      markdown={getMarkdown(
                        page,
                        'ckpojyo9kt6a10c71v3hhdj9e',
                        true
                      )}
                      fragments={getMarkdown(page, 'ckpojyo9kt6a10c71v3hhdj9e')}
                    />
                    <Title>Gestão 2024-2027</Title>
                  </Part>
                )}
                <DropdownContent
                  key={indexList}
                  onClick={() => handleListInformationPeoples(indexList)}
                >
                  {renderDropdown(drop, indexList)}
                </DropdownContent>
              </>
            ))}
          </Part>
          <GridMainServices>
            <Part gridColumn={'2 / -2'}>
              <QualityCommittee
                markdown={getMarkdown(page, 'ckpzku16800m90b79leseo4re', true)}
                fragments={getMarkdown(page, 'ckpzku16800m90b79leseo4re')}
              />
            </Part>
          </GridMainServices>
          <Part
            gridColumn={'2 / -2'}
            paddingDesktop="2.5rem 0 4.375rem"
            paddingMobile="1.25rem 0"
          >
            <TitleSection>Organograma</TitleSection>
            {images.length > 1 && (
              <GraphImg
                image={isMobile ? images[1] : images[0]}
                alt="Organograma"
                fit="clip"
                withWebp={true}
              />
            )}
          </Part>
          <Part
            gridColumn={'2 / -2'}
            largeTitle
            notPadding
            notLeft
            shortPadding
            borderTop
          >
            <TitleSection>Relato Integrado</TitleSection>
            <PromptServiceContent
              markdown={getMarkdown(page, 'cku441y340xtx0b87pitmtr2n', true)}
              fragments={getMarkdown(page, 'cku441y340xtx0b87pitmtr2n')}
              padding
              notTitleBold
              facilitiesForYou
              isBottom
              prontoAtendimento
              isFacilities
              isPatologica
              isMastologia
              contentFontSizeMob="0.875rem"
              contentPadding="0 0 4.375rem"
              contentPaddingLaptop="0 0 1.875rem"
              noPaddingMobile
              withoutBorderTop
            />
          </Part>
          <Part gridColumn={'2 / -2'} largeTitle notLeft borderBottom>
            <InformationCardDownload //previous report
              markdown={getMarkdown(page, 'cku449omw12960f27mqbdy216', false)}
              isContainerMarginInformativeMaterial="4.375rem 0 2.5rem"
              isContainerMarginInformativeMaterialMob="1.875rem 0 0"
            />
          </Part>
          <Part
            gridColumn={'2 / -2'}
            largeTitle
            notPadding
            notLeft
            shortPadding
            borderBottom
          >
            <TitleSection>Relatório Igualdade Salarial</TitleSection>
            <InformationCardDownload //Confira
              markdown={getMarkdown(page, 'club9b9bs4kjo07n2ezhtds8w', false)}
              isContainerMarginBottomInformativeMaterial="1.875rem"
              containerItemsWidth="35.125rem"
              containerItemsHeightLaptop="227px"
              cardItemTitleMaxWidth="25.5rem"
              cardItemTitleFontSizeLaptop="18px"
              cardItemTitleLineHeightLaptop="22px"
              marginRightCard="0px"
            />
          </Part>
          <Part
            gridColumn={'2 / -2'}
            largeTitle
            notPadding
            notLeft
            shortPadding
            borderBottom
          >
            <TitleSection>Demonstrações Financeiras</TitleSection>
            <InformationCardDownload //Confira
              markdown={getMarkdown(page, 'cku311wk80lqz0c24hf8y0x5n', false)}
              isContainerMarginBottomInformativeMaterial="1.875rem"
              containerItemsWidth="35.125rem"
              containerItemsHeightLaptop="227px"
              cardItemTitleMaxWidth="25.5rem"
              cardItemTitleFontSizeLaptop="18px"
              cardItemTitleLineHeightLaptop="22px"
              marginRightCard="0px"
            />
          </Part>
          <Part
            gridColumn={'2 / -2'}
            largeTitle
            notPadding
            notLeft
            shortPadding
            borderBottom
          >
            <TitleSection>Estatuto Social</TitleSection>
            <InformationCardDownload //Confira
              markdown={getMarkdown(page, 'clhhv1aro2a800bk9eapyid6p', false)}
              isContainerMarginBottomInformativeMaterial="1.875rem"
              containerItemsWidth="35.125rem"
              containerItemsHeightLaptop="227px"
              cardItemTitleMaxWidth="25.5rem"
              cardItemTitleFontSizeLaptop="18px"
              cardItemTitleLineHeightLaptop="22px"
              marginRightCard="0px"
            />
          </Part>
        </GridContainer>
      </>
    );
  };

  return (
    <Layout>
      <SEO dataSeo={page} />
      {renderPage(page, isDesktop)}
    </Layout>
  );
};

const PageComponent = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            gestaoHslNucleoOrdem(where: { id: "cl8yvri6bays10bln8jhx89fn" }) {
              gestaoHslNucleos(first: 500) {
                id

                name
                gestaoHsls(locales: pt_BR) {
                  name
                  load
                }
              }
            }
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "clhgc93tk2ppo0bk98e9hrn29" }) {
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                    datafragment
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                  assetpickerMultiple {
                    handle
                    width
                    height
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
                featuredMarketing(locales: [pt_BR, en]) {
                  id
                  type
                  breadcrumb
                  backgroundColor {
                    hex
                  }
                  images {
                    handle
                    width
                    height
                    url
                  }
                  title
                  fragments {
                    id
                    name
                    type
                    markdown
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return (
          <ReadyToAttend
            page={response.gcms.site.pages}
            gestaoHsl={response.gcms.gestaoHslNucleoOrdem.gestaoHslNucleos}
          />
        );
      }}
    />
  );
};
export default PageComponent;
