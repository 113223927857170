// Libs
import React from 'react';
import styled from 'styled-components';

// Components
import { device } from 'components/device';

const ContainerTable = styled.table`
  width: 100%;
  height: 95%;

  tr:nth-child(odd) {
    background-color: #fff;
    border: none;
  }

  tr:nth-child(even) {
    background-color: transparent;
    border: none;
  }

  thead {
    width: 100%;
    border-radius: 8px;
    padding: 0.5rem 0.5rem;
    background-color: transparent;
  }

  td {
    width: 50%;
    padding: 1.125rem 0.625rem;

    @media ${device.mobile} {
      height: 2.875rem;
    }

    :nth-child(1) {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    :nth-child(2) {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  th {
    background-color: #fff;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.625rem;
    color: #231f20;
    border: none;
    padding: 0.875rem 0 0.9375rem 0.625rem;

    @media ${device.laptop} {
      padding: ${props =>
        props.selected ? '.9375rem 0.625rem' : '0.5625rem 00.1875rem 0.655rem'};
    }

    :nth-child(1) {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    :nth-child(2) {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`;

const Text = styled.td`
  font-weight: ${props => (props.office ? '400' : '600')};
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: ${props => (props.office ? '#8f9194' : '#231f20')};
  border: none;

  @media ${device.laptop} {
    padding: ${props =>
      props.office ? '.375rem 0 .375rem .655rem' : '.375rem .24rem .375rem 0'};
    width: ${props => (props.office ? '6.09rem' : '5.6975rem')};
  }
`;

const Table = props => (
  <ContainerTable selected={props.selected}>
    <thead>
      <th>Nome</th>
      <th>Cargo</th>
    </thead>
    {props.data.map(item => (
      <tr key={item.name}>
        <Text>{item.name}</Text>
        <Text office>{item.load || item.localizations?.[0]?.load || '-'}</Text>
      </tr>
    ))}
  </ContainerTable>
);

export default Table;
