// Libs
import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

import { device } from 'components/device';

const Container = styled.div`
  background: #f4f5f7;
  padding: 4.375rem 0 5.5rem 0;
  color: #231f20;
  margin: 0 0 4.375rem;
  margin: 0;
  display: grid;

  @media ${device.laptop} {
    padding-left: 2rem;
  }

  @media ${device.tablet} {
    padding: 1.875rem 1.25rem 5.3125rem 0;
  }

  @media ${device.mobile} {
    padding: 1.875rem 1.125rem 5.3125rem 2.5rem;
  }

  h2 {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.875rem;
    margin-bottom: 1.875rem;

    @media ${device.tablet} {
      font-size: 1.125rem;
      padding-left: 1.4375rem;
      width: 100%;
    }

    :nth-child(3) {
      margin-top: 1.5rem;
    }
  }

  p {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.625rem;

    @media ${device.tablet} {
      padding-left: 1.4375rem;
      width: 90%;
    }

    :nth-child(2) {
      width: 70%;

      @media ${device.tablet} {
        width: 80%;
      }
    }

    :nth-child(7) {
      width: 80%;

      @media ${device.tablet} {
        width: 90%;
      }
    }
  }

  ul {
    display: grid;
    grid-template-columns: repeat(3, 354px);
    column-gap: 1.875rem;
    row-gap: 1.25rem;
    width: 100%;
    margin-bottom: 3.1875rem;
    margin-top: 2.6875rem;

    @media ${device.laptop} {
      display: flex;
      flex-direction: column;
      margin-top: 3.25rem;
    }

    @media ${device.tablet} {
      padding-left: 1.25rem;
    }

    li {
      margin-bottom: 0;
      width: 22.125rem;

      > p {
        max-height: 4.625rem;

        @media ${device.laptop} {
          font-size: 0.875rem;
          padding: 0;
        }
      }

      ::marker {
        color: #45a7df;
      }
    }
  }
`;

const QualityCommitte = ({ markdown }) => (
  <Container>
    <ReactMarkdown children={markdown || '-'} />
  </Container>
);

export default QualityCommitte;
