import styled, { css } from 'styled-components';
import { device } from 'components/device';

export const ContainerCard = styled.div`
  width: 100%;
  max-width: 73.125rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: ${props =>
    props.isContainerMarginBottomInformativeMaterial &&
    props.isContainerMarginBottomInformativeMaterial};

  margin: ${props => props.isContainerMarginInformativeMaterial};

  @media ${device.laptop} {
    display: flex;
    flex-direction: ${props =>
      props.containerCardFlexDirectionLaptop &&
      props.containerCardFlexDirectionLaptop};
    align-items: ${props =>
      props.containerCardAlignItensLaptop &&
      props.containerCardAlignItensLaptop};
    margin-top: ${props =>
      props.containerCardMarginTopLaptop && props.containerCardMarginTopLaptop};
    margin: ${props => props.isContainerMarginInformativeMaterialMob};
  }

  @media ${device.mobile} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const ContainerTitle = styled.div`
  width: ${props =>
    props.containerTitleWidthDesktop
      ? props.containerTitleWidthDesktop
      : '38rem'};
  max-width: 100%;
`;

export const Title = styled.h2`
  width: 100%;
  max-width: 27.9375rem;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.875rem;
  color: #231f20;
  ${props =>
    props.titleMarginDesktop &&
    css`
      margin: ${props.titleMarginDesktop};
    `};

  @media ${device.laptop} {
    width: 100%;
    max-width: 335px;
    font-size: 24px;
    line-height: 22px;
    ${props =>
      props.titleMarginLaptop &&
      css`
        margin: ${props.titleMarginLaptop};
      `};
  }

  @media ${device.mobile} {
    font-size: 18px;
    line-height: 22px;
  }
`;
export const ContainerInformation = styled.div`
  display: flex;
  width: ${props =>
    props.containerInformationWidthDesktop
      ? props.containerInformationWidthDesktop
      : '35.125rem'};
  flex-wrap: wrap;

  @media ${device.laptop} {
    width: ${props =>
      props.containerInformationWidthLaptop &&
      props.containerInformationWidthLaptop};
    flex-direction: column;
    justify-content: center;
  }

  @media ${device.mobile} {
    width: 100%;
  }
`;

export const ContainerItems = styled.div`
  width: ${props =>
    props.containerItemsWidth
      ? props.containerItemsWidth
      : props.isMaterialInSpanish
      ? '35.125rem'
      : '16.875rem'};
  height: 17.125rem;
  border: 1px solid #c6c8cc;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1.875rem;
  margin-right: ${props =>
    props.marginRightCard ? props.marginRightCard : '1.375rem'};

  :nth-child(2n) {
    margin-right: 0;
  }

  @media ${device.laptop} {
    width: 100%;
    height: 186px;
    margin-right: 0;
    height: ${props =>
      props.containerItemsHeightLaptop
        ? props.containerItemsHeightLaptop
        : props.isMaterialInSpanish && '227px'};
  }
  @media ${device.mobile} {
    width: 100%;
    max-width: 100%;
  }
`;

export const CardItemTitle = styled.h2`
  width: 100%;
  max-width: ${props =>
    props.cardItemTitleMaxWidth
      ? props.cardItemTitleMaxWidth
      : props.isMaterialInSpanish
      ? '25.5rem'
      : ' 13.125rem'};
  height: 5.4375rem;
  margin-top: 1.875rem;
  margin-right: 1.875rem;
  margin-left: 1.875rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.8125rem;
  color: #000000;

  @media ${device.laptop} {
    width: 100%;
    max-width: 90%;
    margin-top: 1.25rem;
    margin-right: 1.25rem;
    margin-left: 1.25rem;
    font-size: ${props =>
      props.cardItemTitleFontSizeLaptop
        ? props.cardItemTitleFontSizeLaptop
        : props.isMaterialInSpanish && '18px'};
    line-height: ${props =>
      props.cardItemTitleLineHeightLaptop
        ? props.cardItemTitleLineHeightLaptop
        : props.isMaterialInSpanish && '22px'};
  }

  @media ${device.mobile} {
    font-size: 18px;
    line-height: 22px;
  }

  @media ${device.mobile320} {
    max-width: 80%;
  }
`;

export const ContainerItemCard = styled.div`
  display: flex;
  align-items: center;
`;

export const CardItemImg = styled.img`
  width: 24px;
  height: 24px;
  margin-bottom: 1.875rem;

  @media ${device.mobile} {
    margin-bottom: 20px;
  }
`;

export const CardItemDescription = styled.span`
  width: 100%;
  margin-bottom: 1.875rem;
  margin-left: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #8f9194;

  @media ${device.mobile} {
    margin-bottom: 20px;
    font-size: 14px;
  }
`;

export const CardItemButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 5.0625rem;
  height: 2.8125rem;
  background: #45a7df;
  border-radius: 100px;
  margin-left: 1.875rem;
  margin-right: 2.0625rem;
  margin-bottom: 1.875rem;
  color: #ffffff;
  border: none;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  text-align: center;
  transition: 0.6s background;

  :hover {
    background: #1d52a3;
  }

  @media ${device.mobile} {
    margin-left: 20px;
    margin-right: 23px;
    margin-bottom: 20px;
  }
`;
