import React, { Fragment } from 'react';

import {
  ContainerCard,
  ContainerTitle,
  Title,
  ContainerItems,
  CardItemTitle,
  CardItemButton,
  ContainerInformation,
  ContainerItemCard,
  CardItemImg,
  CardItemDescription,
} from './style';

export function InformationCardDownload({
  markdown,
  isContainerMarginBottomInformativeMaterial,
  isMaterialInSpanish,
  containerItemsWidth,
  containerItemsHeightLaptop,
  cardItemTitleMaxWidth,
  cardItemTitleFontSizeLaptop,
  cardItemTitleLineHeightLaptop,
  marginRightCard,
  containerCardMarginTopLaptop,
  containerCardFlexDirectionLaptop,
  containerCardAlignItensLaptop,
  containerInformationWidthLaptop,
  containerInformationWidthDesktop,
  containerTitleWidthDesktop,
  titleMarginDesktop,
  titleMarginLaptop,
  isContainerMarginInformativeMaterial,
  isContainerMarginInformativeMaterialMob
}) {
  const result = markdown[0];
  if (result) {
    const titleCards = result.singletexts;
    const Cards = result.datafragment.map((resultPage, index) => (
      <Fragment key={index}>
        <ContainerItems
          isMaterialInSpanish={isMaterialInSpanish}
          containerItemsWidth={containerItemsWidth}
          containerItemsHeightLaptop={containerItemsHeightLaptop}
          marginRightCard={marginRightCard}
        >
          <CardItemTitle
            isMaterialInSpanish={isMaterialInSpanish}
            cardItemTitleMaxWidth={cardItemTitleMaxWidth}
            cardItemTitleFontSizeLaptop={cardItemTitleFontSizeLaptop}
            cardItemTitleLineHeightLaptop={cardItemTitleLineHeightLaptop}
          >
            {resultPage.title}
          </CardItemTitle>
          <ContainerItemCard>
            {resultPage.download_link !== '' &&
              resultPage.download_link !== undefined && (
                <CardItemButton target="_blank" href={resultPage.download_link}>
                  Baixar
                </CardItemButton>
              )}
            <CardItemImg
              alt="teste"
              src={resultPage.desc_img.img_link}
            ></CardItemImg>
            <CardItemDescription>
              {resultPage.desc_img.description}
            </CardItemDescription>
          </ContainerItemCard>
        </ContainerItems>
      </Fragment>
    ));
    return (
      <ContainerCard
        isContainerMarginBottomInformativeMaterial={
          isContainerMarginBottomInformativeMaterial
        }
        containerCardMarginTopLaptop={containerCardMarginTopLaptop}
        containerCardFlexDirectionLaptop={containerCardFlexDirectionLaptop}
        containerCardAlignItensLaptop={containerCardAlignItensLaptop}
        isContainerMarginInformativeMaterial={isContainerMarginInformativeMaterial}
        isContainerMarginInformativeMaterialMob={isContainerMarginInformativeMaterialMob}
      >
        <ContainerTitle containerTitleWidthDesktop={containerTitleWidthDesktop}>
          <Title
            titleMarginDesktop={titleMarginDesktop}
            titleMarginLaptop={titleMarginLaptop}
          >
            {titleCards}
          </Title>
        </ContainerTitle>
        <ContainerInformation
          containerInformationWidthLaptop={containerInformationWidthLaptop}
          containerInformationWidthDesktop={containerInformationWidthDesktop}
        >
          {Cards}
        </ContainerInformation>
      </ContainerCard>
    );
  }
}
